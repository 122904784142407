import { Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';

@Component({
  selector: 'app-logout',
  template: '',
  standalone: true
})
export class LogoutComponent implements OnInit {
  private store = inject<Store<fromBaseState.AppState>>(Store);

  ngOnInit() {
    const redirectUri = `${window.location.origin}/login`;
    this.store.dispatch(new fromBaseState.UserLogout(redirectUri));
  }
}
