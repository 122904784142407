import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { getRedirectUrl } from 'libs/utils';
import { StateAfterAuth } from '@ui/shared/models';
import {
  LogoutReasons,
  RESET_PASSWORD_SUCCESS_MESSAGE,
  SessionStorageService
} from 'libs/infrastructure';
import * as fromBaseState from 'libs/infrastructure/base-state';

import { storageKeys } from 'admin/config';

import { LogoComponent } from 'libs/components/legacy';
import { LoginFormComponent } from 'admin/auth/login/components';
import { TranslateModule } from '@ngx-translate/core';
import { UserCredentials } from './models/credentials.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [LogoComponent, LoginFormComponent, TranslateModule],
  standalone: true
})
export class LoginComponent implements OnInit, OnDestroy {
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private route = inject(ActivatedRoute);
  private sessionStorage = inject(SessionStorageService);

  public logoutReason: string;

  public loginError$: Observable<any>;
  public emailVerifiedInCurrentSession = false;

  public showResetPasswordSuccessMessage = false;
  public showChangeEmailSuccessMessage = false;

  private pathAfterAuth: string;
  private queryParamsSubscription: Subscription;

  get expiredTokenError() {
    return this.logoutReason === LogoutReasons.EXPIRED_TOKEN;
  }

  get userDataLoadFailError() {
    return this.logoutReason === LogoutReasons.USER_DATA_LOAD_FAIL;
  }

  ngOnInit() {
    this.loginError$ = this.store.select(fromBaseState.getLoginError);

    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.logoutReason = params.reason;
      this.pathAfterAuth = params.pathAfterAuth;

      if (params && params.message === RESET_PASSWORD_SUCCESS_MESSAGE) {
        this.showResetPasswordSuccessMessage = true;
      }

      if (params && params.message === LogoutReasons.USER_EMAIL_CHANGED) {
        this.showChangeEmailSuccessMessage = true;
      }
    });

    if (
      this.sessionStorage.getItem(storageKeys.emailVerifiedInCurrentSession)
    ) {
      this.emailVerifiedInCurrentSession = true;
      this.sessionStorage.removeItem(storageKeys.emailVerifiedInCurrentSession);
    }
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  public login($event: UserCredentials) {
    const stateAfterAuth: StateAfterAuth = { pathAfterAuth: 'tables' };

    if (this.pathAfterAuth) {
      stateAfterAuth.pathAfterAuth = this.pathAfterAuth;
    }

    const redirectUri = getRedirectUrl(
      window.location.toString(),
      '/auth',
      stateAfterAuth
    );
    this.store.dispatch(
      new fromBaseState.UserLogin({
        email: $event.email,
        redirectUri,
        loginMethod: $event.loginMethod
      })
    );
  }
}
