<form [formGroup]="loginForm">
  <app-form-field>
    <div class="row">
      <div class="col-12">
        <app-form-field-label>{{
          'general.email_address_l' | translate
        }}</app-form-field-label>
      </div>
      <div class="col-12">
        <input
          appInput
          type="email"
          name="email"
          required
          appAutofocus
          [placeholder]="'forms.type_email_address_l' | translate"
          formControlName="email"
          class="form-control"
        />
      </div>
    </div>
  </app-form-field>

  <div class="mt-4">
    <app-button (clickEvent)="loginClick('DEFAULT')" [buttonType]="'submit'">
      {{ 'LOGIN_A' | translate }}
    </app-button>
  </div>
</form>
