import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Store } from '@ngrx/store';

import { Observable, of } from 'rxjs';

import * as fromBaseState from 'libs/infrastructure/base-state';

import { AuthTokenService, SessionStorageService } from 'libs/infrastructure';
import { storageKeys } from 'admin/config';

@Injectable()
export class AuthGuard {
  private store = inject<Store<fromBaseState.AppState>>(Store);
  private authService = inject(AuthTokenService);
  private sessionStorage = inject(SessionStorageService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const token: string = this.authService.getToken()?.access_token;

    this.sessionStorage.setItem(storageKeys.pathAfterAuth, {
      pathAfterAuth: state.url.split('?')[0],
      queryParams: route.queryParams
    });

    if (!token) {
      this.store.dispatch(new fromBaseState.UserRedirect(state.url));
      return of(false);
    }

    return of(true);
  }
}
