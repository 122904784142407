import { Routes } from '@angular/router';

import { TranslationsGuard } from 'libs/infrastructure';

import { AuthGuard, LoginPageGuard } from './guards';
import { AuthComponent, LogoutComponent } from './components';
import { LoginComponent } from './login/login.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    canActivate: [TranslationsGuard],
    component: AuthComponent
  },
  {
    path: 'login',
    canActivate: [TranslationsGuard, LoginPageGuard],
    component: LoginComponent,
    data: {
      title: 'PAGE_LOGIN_1'
    }
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: LogoutComponent
  }
];
