import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  inject
} from '@angular/core';

import { filter } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { customEmailValidator } from 'libs/components/legacy/form/controls/validation';
import {
  AppInputDirective,
  FormFieldComponent,
  FormFieldLabelComponent
} from 'libs/components/legacy';
import { TranslateModule } from '@ngx-translate/core';
import { AutofocusDirective } from 'libs/directives';
import { ButtonComponent } from 'libs/components/atoms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    TranslateModule,
    AppInputDirective,
    AutofocusDirective,
    ButtonComponent
  ],
  standalone: true
})
export class LoginFormComponent implements OnInit {
  private formBuilder = inject(FormBuilder);

  @Output() login = new EventEmitter<any>();
  @Output() requestedPasswordResetEventEmitter = new EventEmitter<any>();

  public loginForm: FormGroup;

  public ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, {
        validators: Validators.compose([
          Validators.required,
          customEmailValidator
        ]),
        updateOn: 'submit'
      })
    });
  }

  public loginClick(loginMethod: string) {
    this.loginForm.statusChanges
      .pipe(filter(status => status === 'VALID'))
      .subscribe(() =>
        this.login.emit({ ...this.loginForm.value, loginMethod })
      );
  }
}
