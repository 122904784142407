import { gql } from 'apollo-angular';

import { GQLError } from '@ui/shared/models';

export const logoutMutation = gql`
  mutation userLogout($redirectUri: String!) {
    userLogout(redirectUri: $redirectUri) {
      url
    }
  }
`;

export interface LogoutResult {
  userLogout: { url: string };
  errors?: GQLError[];
}
