import { gql } from 'apollo-angular';

import { GQLError } from '@ui/shared/models';

export interface SSOQueryResult {
  sso: { nonce: string; state: string; url: string };
  errors?: GQLError[];
}

export const ssoQuery = gql`
  query sso($email: String!, $redirectUri: String!, $loginMethod: String) {
    sso(
      input: {
        email: $email
        redirectUri: $redirectUri
        loginMethod: $loginMethod
      }
    ) {
      nonce
      state
      url
    }
  }
`;
