<div class="login-page__content">
  <app-logo></app-logo>
  <p class="login-page__subtitle uppercase-m">
    {{ 'WELCOME_BACK_L' | translate }}
  </p>
  <h2 class="login-page__title title-xl">
    {{ 'LOGIN_HEADLINE_L' | translate }}
  </h2>

  <app-login-form (login)="login($event)"></app-login-form>
</div>
