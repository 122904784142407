import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { AuthEffects } from './+state';

import { ROUTES } from './auth.routes';

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  providers: [provideState('AuthModuleState', {}), provideEffects(AuthEffects)]
})
export class AuthModule {}
